import { AfterViewInit, Component, OnInit, enableProdMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MisFuncionesService } from './services/mis-funciones.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit{
  public url:string = '';
  public usuario: string = localStorage.getItem('tp-Usuario') || '';
  public barraVisible:boolean = false;
  public ocultarBarra:boolean = false;
  // public barraMenu = document.getElementById('navBar')!;
  public barraMenu: HTMLElement | undefined ;
 


  constructor(
    private misFuncionesService: MisFuncionesService,
  ) { }
  ngAfterViewInit(): void {
    this.barraMenu = document.getElementById('navBar')!;

  }

  ngOnInit(): void {

    

    // El archivo assets configuracion.txt solo debe de usarse para pruebas, ya que si se modifica y no coincide con environment no funcionará el refresco de pantalla ni el enrutamiento porque el auth no actualiza.
    
    this.leerConfiguracion();
    
  }
  title = 'atpv';
  
  leerConfiguracion(){
    // const conf = configuracion;
    // environment.base_url = conf.base_url;
    this.misFuncionesService.leerConfiguracion().subscribe(
      resp=>{
        const config:any = resp;
        // console.log(config);
        
        this.url = config.base_url;
        environment.base_url = config.base_url;
        if (config.activarAvisoFacturasPeriodicas === 'true') {environment.activarAvisoFacturasPeriodicas = true;}
        if (config.activarAvisoStockMinimo === 'true') {environment.activarAvisoStockMinimo = true;}
        if (config.activarCajaEnFacturas === 'true') {environment.activarCajaEnFacturas = true;}
        if (config.activarCobrosEnFacturas === 'true') {environment.activarCobrosEnFacturas = true;}
        if (config.activarClienteFijo === 'true') {environment.activarClienteFijo = true;}
        if (config.activarPrecioConIVAenFormularios === 'true') {environment.activarPrecioConIVAenFormularios = true;}
        if (config.activarTarifas === 'true') {environment.activarTarifas = true;}
        if (config.activarTrazabilidad === 'true') {environment.activarTrazabilidad = true;}
        if (config.activarUnaSolaCaja === 'true') {environment.activarUnaSolaCaja = true;}
        if (config.activarVoz === 'true') {environment.activarVoz = true;}
        if (config.codigoClienteAutomatico === 'true') {environment.codigoClienteAutomatico = true;}
        if (config.fechaLineaTicket === 'true') {environment.fechaLineaTicket = true;}
        if (config.gmt) {environment.gmt = config.gmt;}
        if (config.imprimirAlGrabar === 'true') {environment.imprimirAlGrabar = config.imprimirAlGrabar;}
        if (config.moduloAlmacen === 'true') {environment.moduloAlmacen = true;}
        if (config.moduloBar === 'true') {environment.moduloBar = true;}
        if (config.moduloCaja === 'true') {environment.moduloCaja = true;}
        if (config.moduloCaja2 === 'true') {environment.moduloCaja2 = true;}
        if (config.moduloCamping === 'true') {environment.moduloCamping = true;}
        if (config.moduloOcr === 'true') {environment.moduloOcr = true;}
        if (config.moduloPeluqueria === 'true') {environment.moduloPeluqueria = true;}
        if (config.moduloTaller === 'true') {environment.moduloTaller = true;}
        if (config.moduloTienda === 'true') {environment.moduloTienda = true;}
        if (config.moduloVentas === 'true') {environment.moduloVentas = true;}
        if (config.permitirModificarFacturas === 'true') {environment.permitirModificarFacturas = true;}
        if (config.precioxTemporada === 'true') {environment.precioxTemporada = true;}
        if (config.reportEnSerie === 'true') {environment.reportEnSerie = true;}
      }
    );
  }

  navBarVisible(){
    if (this.barraMenu){
        if (this.barraVisible){
          this.barraMenu.style.setProperty("--posicionFinal", "3px");
          this.barraMenu.style.setProperty("--posicionInicial", "-5px");
          this.barraMenu.style.setProperty("--tiempo", "0.5s");
          if(!this.barraFija) {return 'movimientoVertical';}
          return '';
        }else{
           if(!this.barraFija) {return 'oculto'};
           return ''; 
        }
    }else{
      if(!this.barraFija) {return 'oculto'}; 
      return '';
    }
  }
  ocultarNavBar(){
    this.ocultarBarra = true;
    setTimeout(() => {
      if (this.ocultarBarra){
        this.barraVisible = false;
      }
    }, 1000);
  }

  mostrarNavBar(){
    this.barraVisible = true;
  }

  get barraFija(){
    if (localStorage.getItem('fijarBarra') == 'true'){
      return true;
    }
    return false;
  }
}
