import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { MisFuncionesService } from './mis-funciones.service';
import { Usuario } from '../interfaces/usuario.interface';



const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  
  // token:string = localStorage.getItem('tp-Token') || '';
  // headers:HttpHeaders = new HttpHeaders().set(['Content-Type','application/x-www-form-urlencoded'],['tp-Token','']);

  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  

  constructor(private http: HttpClient, private misFuncionesService: MisFuncionesService,) {

  }

  // get token(): string {
  //   console.log('get Token: '), localStorage.getItem('tp-Token');
    
  //   return localStorage.getItem('tp-Token') || '';
  // }

  // get headers() {
  //   return {
  //     headers: {
  //       'Content-Type': "application/x-www-form-urlencoded",
  //       // 'tp-Token': this.token
  //       'tp-Token': ''
  //     }
      
  //   }
  // }

  pruebas(){
    const url = `${ base_url }/Pruebas`;
    
    return this.http.get(url);
  }

  login(nombre:string, password:string){
    
    // let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');

    const url = `${ environment.base_url }/Login`;
    // const url = `${ base_url }/Login`;
    const data = `nombre=${nombre}&password=${password}`;    
    // return this.http.post(url, data, this.headers)
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // const respuesta:Respuesta = {status:resp.status, detalle:resp.detalle};
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  compruebaToken(){
    // const url = `${ base_url }/tokenvalido`;
    const url = `${ environment.base_url }/TokenValido`;

    // console.log(this.headers);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
  
  auth(){

     
      const url = `${ environment.base_url }/TokenValido/auth`;
    //const url = `${ base_url }/tokenvalido/auth`;
    
    return this.http.get(url, {headers: this.headers})
    // Cambiando la línea de arriba por la de abajo en todos los servicios activaríamos el login sin necesidad de refrescar.
    // return this.http.get(url, {headers: this.misFuncionesService.cabeceras()})
                    .pipe(
                      map(
                        (resp:any)=>{
                          return resp;
                        }
                      )
                    );
  }

  getCombo(orden:string){
    const url = `${ base_url }/Usuarios/getAll?&order=${orden}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  compruebaPin(id:number, pin:string){
    
    const url = `${ environment.base_url }/Login/pinValidado`;
    const data = {
      id: id,
      pin: pin
    };    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busqueda(valor:string, pagina:number, rows:number){
    const url = `${ base_url }/Usuarios/getBusquedaUsuario/${valor}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAll(pagina:number, rows:number, desdeCodigo:string, hastaCodigo:string, desdeNombre:string, hastaNombre:string, orden:string, desc:number){
    const url = `${ base_url }/Usuarios/getAll?page=${pagina}&rows=${rows}&decod=${desdeCodigo}&hacod=${hastaCodigo}&denom=${desdeNombre
                  }&hanom=${hastaNombre}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getUsuarioCodigo(codigo:string){
    const url = `${ base_url }/Usuarios/getUsuarioCodigo/${codigo}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getUsuarioId(id:number){
    const url = `${ base_url }/Usuarios/getUsuarioId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizar(data:Usuario){
    const url = `${ base_url }/Usuarios/putUsuario/${data.id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevo(data:Usuario){
    const url = `${ base_url }/Usuarios/postUsuario`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrar(id:number){
    const url = `${ base_url }/Usuarios/borrarUsuario/${id}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
 }
