import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MensajeComponent } from './mensaje/mensaje.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PaginadorComponent } from './paginador/paginador.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { TabDirective } from './directives/tab.directive';
import { BotoneraComponent } from './botonera/botonera.component';
import { CalculadoraComponent } from './calculadora/calculadora.component';
import { CerradaPipe } from './pipes/cerrada.pipe';
import { BloqueadoPipe } from './pipes/bloqueado.pipe';
import { DomSeguroPipe } from './pipes/dom-seguro.pipe';
import { StringTiempoPipe } from './pipes/string-tiempo.pipe';
import { GaleriaComponent } from './galeria/galeria.component';
import { TelefonoPipe } from './pipes/telefono.pipe';
import { CicloPipe } from './pipes/ciclo.pipe';
import { NgxBarcodeModule } from 'ngx-barcode';
import { OcrComponent } from './ocr/ocr.component';
import { MonederoComponent } from './monedero/monedero.component';





@NgModule({
  declarations: [
       MensajeComponent,
       SpinnerComponent,
       PaginadorComponent,
       BuscadorComponent,
       TabDirective,
       BotoneraComponent,
       CalculadoraComponent,
       CerradaPipe,
       BloqueadoPipe,
       DomSeguroPipe,
       StringTiempoPipe,
       GaleriaComponent,
       TelefonoPipe,
       CicloPipe,
       OcrComponent,
       MonederoComponent,
  ],
  exports: [
       BotoneraComponent,
       BuscadorComponent,
       CalculadoraComponent,
       CerradaPipe,
       CicloPipe,
       GaleriaComponent,
       MensajeComponent,
       MonederoComponent,
       NgxBarcodeModule,
       OcrComponent,
       PaginadorComponent,
       SpinnerComponent,
       StringTiempoPipe,
       TabDirective,
       TelefonoPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ]
})
export class SharedModule { }
