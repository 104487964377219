import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { ImagenesService } from 'src/app/services/imagenes.service';


interface Imagen {
  ruta:string;
  archivo:string;
}

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent implements OnInit {
  @Input() coleccion:string = 'imagenes';
  @Output() imagenClick:EventEmitter<string> = new EventEmitter();
  @Output() cerrarClick:EventEmitter<string> = new EventEmitter();

   // VARIABLES DE COMPONENTES  
   public mensaje:Mensaje={};
   public activarSpinner: boolean = false;
 
   // VARIABLES DE FORMULARIO
 
   public imagenes: Imagen[] = [];

  constructor(
    private imagenesService: ImagenesService
  ) { }

  ngOnInit(): void {
    this.cargarImagenes();
  }

  cargarImagenes(){
    this.activarSpinner = true;
    this.imagenesService.getAll(this.coleccion)
        .subscribe(resp =>{
        if (resp.status ==200) {
        
           this.imagenes = resp.detalle;
        
        }else{
           this.mensaje.textoAlert = resp.detalle;
           this.mensaje.alert = true;
        }
        this.activarSpinner = false
        });
  }

  

  rowClick(indice: number){
    const ruta = this.imagenes[indice].ruta;
    this.imagenClick.emit(ruta);
  }

  cerrar(){
    this.cerrarClick.emit();
  }
}
