import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { ArticulosService } from 'src/app/services/articulos.service';
import { ClientesService } from 'src/app/services/clientes.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { FacturasService } from 'src/app/services/facturas.service';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { MovimientosService } from 'src/app/services/movimientos.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { TabDirective } from 'src/app/shared/directives/tab.directive';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  // NECESARIO PARA ACTIVAR TABULADOR
  @ViewChildren(TabDirective) inputs!: QueryList<TabDirective>;

  public tituloForm:string = 'Login';
  public textoModal:string = '';
  public nombreUsuarioActivo: string = '';
  public activarLogout: boolean = false;
  // public botonModal:any;
  public mensaje:Mensaje={};

  public textoBoton = 'login';
  public activarSpinner:boolean = false;
  public login:boolean = true;
  public activarDemo:boolean = false;

  public formulario:FormGroup = this.fb.group({
      usuario: ['',[Validators.required, Validators.minLength(4)],],
      password: ['',[Validators.required, Validators.minLength(4)]],
    },
    {
    },);

  constructor(
              private fb:FormBuilder,
              private articulosService: ArticulosService,
              private clientesService: ClientesService,
              private empresaService: EmpresaService,
              private facturasService: FacturasService,
              private misFuncionesService: MisFuncionesService,
              private movimientosService: MovimientosService,
              private usuariosService:UsuariosService,
  ) { }

  ngOnInit(): void {
    if (this.activarDemo){this.setearDemo();}
  }

  ngAfterViewInit(): void {
    this.initTab();
    this.comprobarToken();
  }

  initTab(){
    const controls=this.inputs.toArray();
    for (let i=0;i<controls.length-1;i++){
      controls[i].nextControl=controls[i+1].self;
    }
  }


  get spinner():boolean{
    return this.activarSpinner;
  }

   guardar(){
    
    if (this.login){
        this.activarSpinner = true;
        if (this.formulario.invalid) {
          return Object.values(this.formulario.controls).forEach(control => {
            if (control instanceof FormGroup) { 
              return Object.values(control.controls).forEach(control => {control.markAsTouched()})
            }else{
            control.markAsTouched();
        }});
        }else{
          this.usuariosService.login(this.formulario.get('usuario')!.value, this.formulario.get('password')!.value)
                              .subscribe(resp =>{
                                this.activarSpinner = false;
                                if(resp.status =="200"){
                                    // this.mensaje.textoSuccess = `Validación correcta. Pulse F5 o Refresque la página para continuar.`;
                                    this.mensaje.persistente = true;
                                    localStorage.setItem('tp-Token', resp.detalle);
                                    localStorage.setItem('tp-Usuario', this.formulario.get('usuario')!.value);
                                    
                                    this.textoBoton = 'logout'
                                    this.login = false;
                                    // this.cargarClientes();
                                    // this.cargarArticulos();
                                    // this.mensaje.success = true;
                                    this.mensaje.refresh = true;

                                   
                                    
                                }else{
                                    this.mensaje.textoDanger = `Error: ${resp.status}, ${resp.detalle}`;
                                    // this.mensaje.botones = false;
                                    this.mensaje.danger = true;
                                    // this.textoModal = `Error: ${resp.status}, ${resp.detalle}`;
                                }
                                // botonModal?.click();
                                
                              });
                            }  
    }else {
      // localStorage.removeItem('tp-Token');
      // localStorage.removeItem('tp-Usuario');
      // this.login = true;
      // this.textoBoton = 'login';
      // this.formulario.reset();
      // this.mensaje.textoSuccess = 'Desconectado con éxito';
      // this.mensaje.success = true;
      this.logout();
    }
    
  }

 
  campoNoValido( campo: string){
    return (this.formulario.controls[campo].errors && this.formulario.controls[campo].touched)
  }

  comprobarToken(){
   const token = localStorage.getItem('tp-Token');
   const usuario = localStorage.getItem('tp-Usuario');
   
   if (!token || !usuario){
    //  console.log('No hay token o usuario');
    // if (this.activarDemo){this.setearDemo();}
   }else{
    //  console.log('compruebo token');



     this.usuariosService.compruebaToken()
                         .subscribe(resp =>{
                         if (resp.status !=200) {
                            this.mensaje.textoDanger = `Error: ${resp.status}, ${resp.detalle}`;
                            this.mensaje.danger = true;
                            
                            this.logout();
                         }else{
                          this.textoBoton = 'logout';
                          this.login = false;
                           this.formulario.reset({
                             usuario: usuario
                           });
                          //  environment.token = token;
                          //  environment.usuario = usuario;
                          //  this.cargarClientes();
                          //  this.cargarArticulos();
                          //  this.mensaje.textoSuccess = `Bienvenido ${this.formulario.get('usuario')!.value}`;
                           this.nombreUsuarioActivo = this.formulario.get('usuario')!.value;
                           this.activarLogout = true;
                          if (this.activarDemo){this.ponerBarraFija();}
                          //  this.mensaje.success = true;

                          //  this.cargarEmpresa();
                           if (environment.activarAvisoFacturasPeriodicas){this.controlFacturasPeriodicas();}
                           if (environment.activarAvisoStockMinimo){this.controlStockMinimo();}

                         }
                         });
   }
   
   
   
 }
  
  logout(){
      this.activarLogout = false;
      
      localStorage.removeItem('tp-Token');
      localStorage.removeItem('tp-Usuario');
      // environment.token = '';
      // environment.usuario = '';
      this.login = true;
      this.textoBoton = 'login';
      this.formulario.reset();
      this.mensaje.textoSuccess = 'Desconectado con éxito';
      this.mensaje.success = true;
      if (this.activarDemo){this.setearDemo();}
  }

  cargarClientes(){
    this.activarSpinner = true;
    this.clientesService.getCombo('nombreFiscal')
                        .subscribe(
                          resp => {
                            if(resp.status != 200) {
                              this.mensaje.textoAlert = resp.detalle;
                              this.mensaje.alert = true;
                            }else {
                              environment.clientes = resp.detalle;
                            }  
                            this.activarSpinner = false;
                          }
                        );
  }

  cargarArticulos(){
    this.activarSpinner = true;
    this.articulosService.getCombo('descripcion')
                        .subscribe(
                          resp => {
                            if(resp.status != 200) {
                              this.mensaje.textoAlert = resp.detalle;
                              this.mensaje.alert = true;
                            }else {
                              environment.articulos = resp.detalle;
                            }  
                            this.activarSpinner = false;
                          }
                        );
  }


  controlStockMinimo(){
    this.activarSpinner = true;
    this.movimientosService.getMercanciaStockBajo(environment.almacenDefecto, 1, 45, 'descripcionArticulo' , 0)
                                          .subscribe(resp =>{
                                          if (resp.status == 200) {
                                            if(resp.detalle.length > 0){
                                              this.mensaje.textoWarning = 'Existen Artículos bajo stock mínimo.'
                                              this.mensaje.persistente = true;
                                              this.mensaje.warning = true;
                                            }
                                          }
                                          this.activarSpinner = false
                                          });
  }

  controlFacturasPeriodicas(){
    this.activarSpinner = true;
    this.facturasService.getAllPeriodicas(1, 15, 0, 9999999999, '', environment.marcaFinal, 
      '', environment.marcaFinal, '', environment.marcaFinal, '1900-01-01', this.misFuncionesService.finDeLosTiempos() , '1900-01-01', this.misFuncionesService.ultimoDiaDelMes(),
      'null', this.misFuncionesService.finDeLosTiempos(), 'id', 0)
                                          .subscribe(resp =>{
                                          if (resp.status == 200) {
                                            if(resp.detalle.length > 0){
                                              this.mensaje.textoInfo = 'Existen facturas periódicas pendientes de cursar.'
                                              this.mensaje.persistente = true;
                                              this.mensaje.info = true;
                                            }
                                          }
                                          this.activarSpinner = false
                                          });
  }

  // cargarEmpresa(){
  //   this.empresaService.getEmpresa()
  //                      .subscribe(
  //                        resp => {
  //                         if(resp.status =="200"){
  //                           this.cargarParametros(resp.detalle.codigo);
                            
  //                         } else {
  //                           this.mensaje.textoDanger = `Error: ${resp.status}, ${resp.detalle}`;
  //                           this.mensaje.danger = true;
  //                         }
  //                        }
  //                      );
  // }

  // cargarParametros(codigoEmpresa: string) {
  //   switch (codigoEmpresa) {
  //     case 'demo':
  //       //  environment.activarAvisoFacturasPeriodicas = true;
  //       //  this.controlFacturasPeriodicas();
        
  //       break;
    
  //     default:
  //       console.log('No llega código');
        
  //       break;
  //   }
  // }

  setearDemo(){
    
    this.formulario.reset({
      usuario: 'demo',
      password: 'demo'
    })
  }

  ponerBarraFija(){
      setTimeout(() => {
        localStorage.setItem('fijarBarra', 'true');
      }, 2000);
  }

}
