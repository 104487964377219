<div class="buscador fade-in-image">
    <div class="row">
        <div class="col-lg-11"></div>

        <button (click)="cancelar();" type="button" class="btn col-lg-1">
                    
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
              </svg>
            
            </button>

    </div>
    <h2>{{titulo}}</h2>
    <form autocomplete="off" [formGroup]="formulario" (ngSubmit)="cancelar()">
        <div class="form-group row mt-3">
            <label class="col-lg-2 col-md-3 col-form-label text-end">Búsqueda</label>
            <div class="col-lg-4 col-md-8">
                <input #entrada (input)="teclaPresionada($event)" class="form-control" id=entrada type="text" placeholder="Entrada" formControlName="entrada">
            </div>
        </div>
    </form>



    <!-- ----------------------------------------------------------------------- -->
    <!--                                  TABLA                                  -->
    <!-- ----------------------------------------------------------------------- -->


    <table *ngIf="rows.length > 0" class="table table-sm table-striped caption-top table-responsive mt-3">
        <thead class="clickable">
            <tr class="table-dark">
                <th *ngFor="let col of columns" scope="col" colspan="2">{{col}}</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of rows; let i=index" (click)="rowClick(i)" class="clickable">
                <th scope colspan="2">{{row.campo1}}</th>
                <td colspan="2">{{row.campo2}}</td>
                <td colspan="2">{{row.campo3}}</td>
                <td colspan="2">{{row.campo4}}</td>
                <td colspan="2">{{row.campo5}}</td>
            </tr>
        </tbody>
    </table>




    <!-- ----------------------------------------------------------------------- -->
    <!--                                PAGINADOR                                -->
    <!-- ----------------------------------------------------------------------- -->

    <app-paginador *ngIf="totalPaginas > 1" (onClick)="consultar($event);" [totalPaginas]="totalPaginas" [paginaActiva]="paginaActiva"></app-paginador>


    <!-- ----------------------------------------------------------------------- -->
    <!--                            BOTONES (INICIO)                             -->
    <!-- ----------------------------------------------------------------------- -->

    <!-- <div class="row mb-2">
        <div class="col-lg-8">

        </div>
        <div class="btn-toolbar col-lg-4" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group-lg me-4" role="group" aria-label="First group">

                <button type="submit" class="btn btn-primary">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                          </svg>
                    </div>
                    Aceptar
        </button>






            </div>




            <div class="btn-group-lg me-2" role="group" aria-label="Second group">
                <button (click)="cancelar();" type="button" class="btn btn-danger boton-cancelar">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                          </svg>

                    </div>
                        Cancelar
                    </button>

            </div>

        </div>
    </div> -->

    <!-- ----------------------------------------------------------------------- -->
    <!--                              BOTNOES(FIN)                               -->
    <!-- ----------------------------------------------------------------------- -->












</div>