<!-- <div class="container fondoFormulario bordeContenedor"> -->
<div class="buscador fade-in-image">
    <h2>Lector Documentos</h2>
    <br>
    <div class="text-center">
        <img src="{{imagen}}" class="carnet ">
    </div>


</div>

<app-spinner *ngIf="activarSpinner"></app-spinner>
<app-mensaje [mensaje]=mensaje></app-mensaje>

