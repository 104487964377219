<div class="text-end mt-2">
    <button type="button" class="btn-close" aria-label="Close" (click)="cerrar();"></button>
</div>
<div *ngFor="let imagen of imagenes;let i =index" class="galeriaImagenes border">
    <div (click)="rowClick(i)" class="card clickable" style="width: auto; height: 200px;">
        <img src="{{imagen.ruta}}">
        <div class="card-body">
            <h5 class="card-title">{{imagen.archivo}}</h5>

        </div>
    </div>
</div>

<app-spinner *ngIf="activarSpinner"></app-spinner>
<app-mensaje [mensaje]=mensaje></app-mensaje>