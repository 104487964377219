import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-paginador',
  templateUrl: './paginador.component.html',
  styleUrls: ['./paginador.component.css']
})
export class PaginadorComponent implements OnInit {
  
  // @Input() paginas: number[] = [];
  @Input() totalPaginas: number= 0;
  @Input() paginaActiva: number = 1;
  @Output() onClick:EventEmitter<number> = new EventEmitter();

  paginas: number[] = [];

  constructor() { }

  ngOnInit(): void {
    this.configurarPaginador();
  }

  consultar(pagina:number){
    // console.log('Página desde el paginador: ',pagina);
    
    this.paginaActiva = pagina;
    this.configurarPaginador();
    this.onClick.emit(pagina);
  }


  activarPagina(pagina:number){
    if (pagina == this.paginaActiva) { return "page-item active clickable debajo";}
    return "page-item clickable debajo"
  }
  
  aumentarPagina() {
    this.paginaActiva++;
    // if (this.paginaActiva > this.paginas.length){this.paginaActiva = this.paginas.length;}
    if (this.paginaActiva > this.totalPaginas){this.paginaActiva = this.totalPaginas;}
    // this.configurarPaginador();
    this.consultar(this.paginaActiva);
  }

  configurarPaginador(){
    // let i = 0;
    // console.log('Pagina Activa: ', this.paginaActiva);
    // console.log('Total Páginas: ', this.totalPaginas);
    
    let i = this.paginaActiva - 4;
    if (i < 0 ) {i = 0;}
    this.paginas = [];
    while (i < this.totalPaginas && i < this.paginaActiva + 3) {
      this.paginas.push(i + 1);
      i++;
    }
  }

  disminuirPagina() {
    --this.paginaActiva;
    if (this.paginaActiva < 1){this.paginaActiva = 1;}
    // this.configurarPaginador();
    this.consultar(this.paginaActiva);
  }
  
 
  
}
